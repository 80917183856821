'use client';

import Link from 'next/link';
import Image from 'next/image';
import { motion, useMotionValueEvent, useScroll, Variants } from 'framer-motion';
import { useEffect, useState } from 'react';
import Logo from '@/Logo.svg';
import HomeButton from './HomeButton';
import { useTranslation } from '../i18n/useTranslation';
import KeepQueryLink from './KeepQueryLink';
import BlogLink from './BlogLink';

const variants: Variants = {
  top: {
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '0.25em',
    paddingBottom: '0.25em',
  },
  scrolled: {
    paddingLeft: '2em',
    paddingRight: '2em',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
  },
};

export default function Header() {
  const { t } = useTranslation();

  function checkIsMobile() {
    if (typeof window === 'undefined') {
      return false;
    }
    return window.matchMedia('(max-width: 640px)').matches;
  }
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  useEffect(() => {
    function handleResize() {
      setIsMobile(checkIsMobile());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const { scrollY } = useScroll();
  const [currentVariant, setCurrentVariant] = useState<'top' | 'scrolled'>('top');
  useMotionValueEvent(scrollY, 'change', (value) => {
    if (isMobile) {
      return setCurrentVariant('top');
    }
    return setCurrentVariant(value > 50 ? 'scrolled' : 'top');
  });

  useEffect(() => {
    if (currentVariant === 'scrolled' && isMobile) {
      setCurrentVariant('top');
    }
  }, [isMobile, setCurrentVariant, currentVariant]);

  return (
    <motion.header
      initial="top"
      animate={currentVariant}
      variants={variants}
      transition={{ duration: 0.5 }}
      className="flex justify-center h-[8dvh] sticky top-0 z-20"
    >
      <div className="px-4 py-2 flex items-center justify-between w-full bg-stone-100/70 backdrop-blur border rounded-xl border-stone-100/50">
        <Link href="/" className="w-fit flex items-center">
          <Image className="w-32 h-auto transform -translate-y-1" src={Logo.src} width={Logo.width} height={Logo.height} alt="Conheça a Ótmow" />
        </Link>
        <ul className="hidden md:flex items-center gap-10 text-base font-medium">
          <li className="text-black/70 hover:text-black hover:underline transition-all">
            <Link href="/#about-us">
              {t('landing.header.about-us')}
            </Link>
          </li>
          <li className="text-black/70 hover:text-black hover:underline transition-all">
            <Link href="/#testimonials">
              {t('landing.header.testimonials')}
            </Link>
          </li>
          <li className="text-black/70 hover:text-black hover:underline transition-all">
            <Link href="/#how-it-works">
              {t('landing.header.how-it-works')}
            </Link>
          </li>
          <li className="text-black/70 hover:text-black hover:underline transition-all">
            <BlogLink>
              {t('landing.header.blog')}
            </BlogLink>
          </li>
        </ul>
        <KeepQueryLink href="/intake">
          <HomeButton>
            {t('landing.header.apply')}
          </HomeButton>
        </KeepQueryLink>
      </div>
    </motion.header>
  );
}
