'use client';

import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import React, { Suspense } from 'react';

type Props = {
  className?: string;
  href: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  query?: Record<string, string>;
  hash?: string;
};
function QueryLink({
  className = '', href, query = {}, hash = undefined, onClick = undefined, children = undefined,
}: React.PropsWithChildren<Props>) {
  const defaults = {};
  const [pathname, rootQuery] = href.split('?');
  const params = useSearchParams();
  const computedQuery = {
    ...defaults,
    ...Object.fromEntries(new URLSearchParams(rootQuery).entries()),
    ...Object.fromEntries(params.entries()),
    ...query,
  };

  return (
    <Link className={className} onClick={onClick} href={{ pathname, query: computedQuery, hash }}>
      {children}
    </Link>
  );
}

export default function KeepQueryLink({
  className = '', href, query = {}, onClick = undefined, children = undefined, hash = undefined,
}: React.PropsWithChildren<Props>) {
  return (
    <Suspense
      fallback={(
        <Link className={className} href={hash ? `${href}#${hash}` : href} onClick={onClick}>
          {children}
        </Link>
      )}
    >
      <QueryLink className={className} href={href} query={query} hash={hash} onClick={onClick}>
        {children}
      </QueryLink>
    </Suspense>
  );
}
